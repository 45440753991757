/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.0.0-v202410082311-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqAnnotationsApiClass {
 
   constructor() {}

  /**
   * @summary Archive an annotation
   * @param {string} id - ID of the annotation to archive
   */
  public archiveAnnotation(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/annotations/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @param {string} imageUrl
   * @param {string} [accept]
   */
  public corsImageProxy(
    {
      imageUrl,
      accept
    } : {
      imageUrl: string,
      accept?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(imageUrl)) {
      throw new Error("'imageUrl' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/annotations/image/cors/${encodeURIComponent(String(imageUrl))}`,

      headers: {
        ['Accept']: accept as string
      },
      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Create an annotation
   */
  public createAnnotation(
    body: models.AnnotationInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/annotations`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AnnotationOutputV1>;
  }

  /**
   * @summary Delete an image from a journal entry
   * @param {string} id - The ID of the Annotation that uses the image
   * @param {string} fileName - The file name of the image
   */
  public deleteImage(
    {
      id,
      fileName
    } : {
      id: string,
      fileName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(fileName)) {
      throw new Error("'fileName' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/annotations/${encodeURIComponent(String(id))}/images/${encodeURIComponent(String(fileName))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Get an annotation
   * @param {string} id - ID of the annotation to retrieve
   */
  public getAnnotation(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/annotations/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AnnotationOutputV1>;
  }

  /**
   * @summary Get a collection of annotations
   * @param {Array<string>} [annotates] - A list of item IDs that will filter the annotations returned to only those that annotate the specified items.
   * @param {string} [type] - The type of annotations to return, either Report or Journal
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getAnnotations(
    {
      annotates,
      type,
      offset,
      limit
    } : {
      annotates?: Array<string>,
      type?: string,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/annotations`,

      params: omitBy({
        ['annotates']: annotates,
        ['type']: type,
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AnnotationListOutputV1>;
  }

  /**
   * @summary Get an image for a journal entry
   * @param {string} id - The ID of the Annotation that uses the image
   * @param {string} fileName - The file name of the image
   */
  public getImage(
    {
      id,
      fileName
    } : {
      id: string,
      fileName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(fileName)) {
      throw new Error("'fileName' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/annotations/${encodeURIComponent(String(id))}/images/${encodeURIComponent(String(fileName))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Mark the annotation as upgraded to CK Editor using the provided HTML
   * @param {string} id - The Seeq ID for the report
   */
  public migrateAnnotationToCkEditor(
    body: models.MigrateEditorInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/annotations/editorMigration/${encodeURIComponent(String(id))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Publish a report so it cannot be changed
   * @param {string} id - ID of the report to publish
   */
  public publishReport(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/annotations/${encodeURIComponent(String(id))}/publish`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AnnotationOutputV1>;
  }

  /**
   * @summary Update an annotation
   * @param {string} id - ID of the annotation to update
   */
  public updateAnnotation(
    body: models.AnnotationInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/annotations/${encodeURIComponent(String(id))}/update`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AnnotationOutputV1>;
  }

  /**
   * @summary Upload an image for a journal entry
   * @param {string} id - The ID of the Annotation that will use the image
   * @param {Blob} [file]
   */
  public uploadImage(
    {
      id,
      file
    } : {
      id: string,
      file?: File
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    const formData = new FormData();
    formData.append('file', file as File, file?.name);

    return getAxiosInstance().post(
      APPSERVER_API_PREFIX + '/annotations/${encodeURIComponent(String(id))}/images'
      .replace('{id}', encodeURIComponent(String(id))),
      formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      ...extraHttpRequestConfig,
    }) as AxiosPromise<models.AnnotationImageLinkOutputV1>;
  }

}


export const sqAnnotationsApi = new sqAnnotationsApiClass();
